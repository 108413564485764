import React, { useEffect, useContext } from 'react'
import { Link } from "gatsby"
import { ObserverContext } from "../provider/IntersectionObserverProvider";
import { handleObserver } from '../utils/IntersectionObserver'

const CallToAction = () => {
  const { toTargets, targets } = useContext(ObserverContext);

  useEffect(() => {
    handleObserver(targets)
  }, []);

  return (
    <div className="ctaContainer">
      <div className="ctaWrapper fade-in up" ref={toTargets}>
        <p>ワカチエで、新たなコミュニケーションのカタチを体感してみませんか？</p>
        <Link to="/trial" className="trialLink" >ワカチエを無料で使ってみる</Link>
      </div>
    </div>
  )
}

export default CallToAction
