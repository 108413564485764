import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import pdfFile from '../../images/pdf/wakachie_whitepaper_202402.pdf';
import SecondPageHeader from "../../container/secondPageHaaderContainer"
import CallToAction from "../../container/ctaContainer"
import {
  trackingEvent,
  CLICK_ACTION,
  DOWNLOAD_PAGE_CATEGORY,
  WHIEPAPER_DOWNLOAD_LABEL,
} from "../../tools/Tracking"

const KawaiiCharacter = () => (
  <Layout>
    <Seo title="「かわいさ」が会議を変える！仕事を変える！[ホワイトペーパー] | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | ワカチエ" />
    <SecondPageHeader englishTitle="- White Paper -" mainTitle="ホワイトペーパー 「かわいさ」が会議を変える！仕事を変える！" isShowTrialLink={true} />
    <div className="whitePaperPage secondPageContens">
      <a href={pdfFile} download className="linkButton" onClick={() =>
          trackingEvent(CLICK_ACTION, DOWNLOAD_PAGE_CATEGORY, WHIEPAPER_DOWNLOAD_LABEL)
        }>
        PDFファイルをダウンロード
      </a>
      <StaticImage src="../../images/img_whitepaper_202402_1.png" className="whitePaperImage" alt={"ホワイトペーパー 「かわいさ」が会議を変える！仕事を変える！ 1"} />
      <StaticImage src="../../images/img_whitepaper_202402_2.png" className="whitePaperImage" alt={"ホワイトペーパー 「かわいさ」が会議を変える！仕事を変える！ 2"} />
      <StaticImage src="../../images/img_whitepaper_202402_3.png" className="whitePaperImage" alt={"ホワイトペーパー 「かわいさ」が会議を変える！仕事を変える！ 3"} />
      
    </div>
    <CallToAction />
  </Layout>
)

export default KawaiiCharacter
