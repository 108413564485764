import React from "react"
import { Link } from "gatsby"

const SecondPageHeader = (props) => {
  const { englishTitle, mainTitle, subTitle, isShowTrialLink} = props;
  return (
    <div className="SecondPageHeaderContainer">
      <div className="headerContent">
        <p className="eng">{englishTitle}</p>
        <h2>{mainTitle}</h2>
        { subTitle ? <p className="subTitle" >{subTitle}</p> : null }
        { isShowTrialLink ? <Link to="/trial" className="trialLink" >無料トライアル</Link> : null }
      </div>
    </div>
  )
}

export default SecondPageHeader
